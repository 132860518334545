import { Contract } from "@react-store/common/src/utils/types/ContractTypes";
import { Alert, Box, Button, Card, CardActions, CardContent, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import ApartmentIcon from '@mui/icons-material/Apartment';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import * as moment from 'moment'
import 'moment/locale/fr';
//import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import * as React from "react";
//import { StoreSharePointSite, StoreSharePointLists, StoreSharePointAddItem } from "../../../hooks/SharePointStore";
import { GedSharepointListItem } from "@react-store/common/src/utils/types/SharePointTypes";
import { StoreAddContractStatus, StoreContractStatus, StoreRequestProjectWorkspace, StoreGetCodeSp } from "../../../hooks/ContractStore";
import { useEffect } from "react";
export function ContractDetails({ choosenContract }: any) {


  const { searchStatus, responseStatus } = StoreContractStatus();
  const { addStatus, responseAddedItem } = StoreAddContractStatus();
  const [isClicked, setIsClicked] = React.useState(false);
  const { getCodeSp, responseCodeSp} = StoreGetCodeSp();

  const { requestProjectWorkspace, responseRequestProjectWorkspace, errorRequestProjectWorkspace } = StoreRequestProjectWorkspace();

  let choosenContractOne: Contract = choosenContract[0];

  useEffect(() => {
    searchStatus(choosenContractOne.project_id)
  }, [choosenContractOne, responseAddedItem]);

  useEffect(() => {
    getCodeSp(choosenContractOne.service_id)
  }, [choosenContractOne]);

  useEffect(() => {
    requestWorkspace();
  }, [isClicked]);

  function requestWorkspace() {

    /*const formatter = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const formattedDate = formatter.format(choosenContractOne.start_date);*/

    var itemToAdd: GedSharepointListItem = {
      title: choosenContractOne.project_name,
      idIovision: choosenContractOne.project_id,
      typeProject: '',
      contactChefProjet: choosenContractOne.project_supervisor_email,
      contactCommercial: choosenContractOne.project_sales_representative_email,
      client: choosenContractOne.customer_name,
      serviceId:choosenContractOne.service_id,
      dateProjet:choosenContractOne.start_date.toString().split('T')[0]
    };
    if(isClicked == true && responseCodeSp?.code_sp !=null){
      requestProjectWorkspace(choosenContractOne.project_id, itemToAdd);
      addStatus(choosenContractOne.project_id);
    }
  }



  const card = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5" component="div">
          Identifiant projet:  {choosenContractOne.project_id}
        </Typography>
        <br />
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {choosenContractOne.project_key}: {choosenContractOne.project_name}
        </Typography>
        <br />
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ApartmentIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary={choosenContractOne.service_name} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <CoPresentIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary={choosenContractOne.customer_name} />
            </ListItemButton>
          </ListItem>
        </List>
      </CardContent>
      <CardActions>
        {(responseStatus?.length == 0 && responseCodeSp?.code_sp != null) && (<Button
          variant="contained"
          size="small"
          type="submit"
          disabled={responseStatus?.length != 0}
          onClick={ ()=>setIsClicked(true)}
        >
          création de l'espace documentaire de l'étude
        </Button>
        )}
        {(responseCodeSp?.code_sp == null) && (<Alert severity="error">Le code sharepoint du service n'existe pas</Alert>)}
        {(responseStatus?.length != 0) && (<Alert severity="info">La demande de création a été effectuée par {responseStatus?.[0]?.created_by} le {moment.locale("fr") && moment(responseStatus?.[0]?.created).format('LLL')}</Alert>)}
      </CardActions>
    </React.Fragment>);

  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">{card}
      </Card>
      {(false && !errorRequestProjectWorkspace && responseRequestProjectWorkspace?.id != undefined) && (<Alert severity="success">L'espace est crée avec succès.</Alert>)}
      {(false && errorRequestProjectWorkspace != undefined && responseAddedItem != undefined) && (<Alert severity="error">Une erreur est survenue.</Alert>)}
    </Box>

  );
}