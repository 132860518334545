//import { GedApi } from "./configs/Api";
import {GedApiBack} from "./configs/Api";
import { GedSharepointListItem, WorkspaceResponse } from "@react-store/common/src/utils/types/SharePointTypes";

export const GedService = {

  getContract: async function (value:number/*,userService:string*/) {
    const response = await GedApiBack.request({
        url: `/project-iovision?q=${value}&page=10`,
        //url: `/project-iovision?q=${value}&service=${userService}&page=10`,
        method: "GET",
      })
    return response.data
  },

  getContractStatus: async function (value:number) {
    const response = await GedApiBack.request({
        url: `/project-status?q=${value}`,
        method: "GET",
      })
    return response.data
  },

  addContractStatus: async function (value:number) {
    const response = await GedApiBack.request({
        url: `/project-status/${value}`,
        method: "POST",
      })
    return response.data
  },

  requestProjectWorkspace: async function (value:number, data:GedSharepointListItem):Promise<WorkspaceResponse> {

    const response = await GedApiBack.request({
        url: `/project-iovision/${value}/workspace`,
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        data: data,
        body: 'raw'
       
      })
    return response.data
  },

  getCodeSp: async function (value:number) {
    const response = await GedApiBack.request({
      url: `/service-lookup/${value}`,
      method: "GET",
    })
  return response.data
  }

}
