import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import iovision_research from "../../assets/images/iovision_research_.png"

export function HelpMessageTemplate() {
  return (
    <DialogContent dividers>
    <Typography gutterBottom>
      1. Trouver l'<strong>identifiant</strong> dans Iovision.
    </Typography>
    <img src={iovision_research} alt="iovision_research" />
    <Typography gutterBottom>
       2. Rechercher l'étude dans la barre de recherche avec cet <strong>identifiant</strong>.
    </Typography>
    <Typography gutterBottom>
       3. Faire une demande de création d'un espace documentaire une fois le projet trouvé.
    </Typography>
    </DialogContent>
  );
}
export default HelpMessageTemplate;