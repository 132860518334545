import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import logoGed from "../assets/images/ged-file-icon.svg";
import Aside from "@react-store/common/src/components/app/AsideLogin";
import Main from "./Main";
import { InteractionStatus } from "@azure/msal-browser";
import { GraphData } from "@react-store/common/src/components/ui/ProfileData";
import { callMsGraphById } from "@react-store/common/src/utils/MsGraphApiCall";
import { useState, useEffect } from "react";
import { loginRequest as loginRequestGed} from "../../../ged/src/authConfigGed";

export function Home() {
const { instance, inProgress ,accounts} = useMsal();
const [graphData, setGraphData] = useState<null|GraphData>(null);

const account = instance.getActiveAccount() ;
/*  if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }*/

useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None && account) {
        instance
        .acquireTokenSilent({
            ...loginRequestGed,
            account: accounts[0],
        })
        .then((response) => {
          callMsGraphById(response.accessToken)
            .then(data => {
                setGraphData(data);
            });
        });
    }
}, [inProgress, graphData, instance]);
return (
    <>
      <AuthenticatedTemplate>
        <Container maxWidth="lg">
          <Main graphData = {graphData}/>
        </Container>
      </AuthenticatedTemplate>
      
      <UnauthenticatedTemplate>
        <Aside />
        <Box
          sx = {{
            textAlign: "center",
            position: "fixed",
            top: "30%",
            left: "55%",
            zIndex: 5,
          }}
        >
        <Box
          component="img"
          sx={{
            width: "375px",
            height: "300px",
          }}
          src={logoGed}
        />
          <Typography
            color="inherit"
            variant="h5"
            fontWeight={600}
          >
            Gestion documentaire des études
          </Typography>
        </Box>
      </UnauthenticatedTemplate>
    </>
);
}
